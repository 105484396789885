import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    border: 1px solid black;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 0;
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow-y: hidden;

    @media(max-width: 1100px){
        width: 90%;
    }

    .image{
        width: 90%;
        height: 350px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border-radius: 20px;

        img{
            height: 100%;
        }
    }

    h2{
        font-size: 2.2rem;
        margin: 10px;
        text-align: center;
        width: 90%;

        @media(max-width: 1100px){
            font-size: 3rem;
        }

        @media(max-width: 1100px){
            font-size: 3.5rem;
        }
    }

    p{
        font-size: 1.5rem;
        text-align: center;
        margin: 10px 0;
        width: 90%;

        @media(max-width: 1100px){
            font-size: 2.5rem;
        }

        @media(max-width: 600px){
            font-size: 2rem;
        }
    }

`;

const Card = (props) => {
    return (
        <Container>
            <div className="image">
                <img src={props.image} alt="Amenity Card"/>
            </div>
            <h2>{props.header}</h2>
            <p>{props.text}</p>
        </Container>
    );
}

export default Card;