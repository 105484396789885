import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API } from '../url';

const Container = styled.div`
    width: 100vw;

    .hero{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(4, 44, 109);
        color: white;
        height: 80vh;
        width: 100%;

        @media(max-width: 1100px){
            height: 40vh;
        }

        h1{
            font-size: 4rem;
            margin: 20px 0;
            text-align: center;

            @media(max-width: 1100px){
                font-size: 3rem;
            }
    
        }

        p{
            font-size: 1.8rem;
            width:50%;
            text-align: center;

            @media(max-width: 800px){
                line-height: 30px;
                width: 90%;
            }
        }
    }

    .form{
        width: 100%;
        display: flex;
        justify-content: center;

        form{
            display: flex;
            flex-direction: column;
            width: 60%;
            padding: 40px 0;

            @media(max-width: 800px){
                width: 90%;
            }

            label{
                font-size: 2rem;
                color: black;
                padding: 0 0 15px 0;
            }

            input{
                font-size: 1.6rem;
                margin-bottom: 30px;
                border-radius: 5px;
                border: 1px solid black;
                height: 2.6rem;
            }

            textarea{
                font-size: 2rem;
                margin-bottom: 30px;
                height: 150px;
                border-radius: 5px;
                border: 1px solid black;
            }

            button{
                font-size: 1.6rem;
                background: rgb(4, 44, 109);
                color: white;
                height: 50px;
                border-radius: 5px;
                border: 1px solid black;
            }
        }
    }
`;

const Contact = (props) => {

    const [data, setData] = useState({
        name_field: '',
        email_field: '',
        message_field: '',
        formCheck: ''
    })

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const submit = (e) => {
        e.preventDefault();
        axios
            .post(`${API}/resthave-general-contact`, data)
            .then(res => {
                setData({
                    name_field: '',
                    email_field: '',
                    message_field: '',
                    formCheck: ''
                })
                alert("Your message has been received. Thank you!");
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Container>
            <section className="hero">
                <h1>Contact Resthave</h1>
                <p>Please use this form to contact Resthave for all general inquiries. If you are looking to volunteer, or apply for a job, please scroll down.</p>
            </section>
            <section className="form">
                <form onSubmit={submit}>
                    <label htmlFor="name">Name</label>
                    <input
                        type='text'
                        name='name_field'
                        onChange={handleChange}
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type='text'
                        name='email_field'
                        onChange={handleChange}
                    />
                    <label htmlFor="message">Message</label>
                    <textarea
                        type='text'
                        name='message_field'
                        onChange={handleChange}
                    />
                    <label htmlFor="verify">Please prove that your a human. A Zebra is Black and ?????</label>
                    <input
                        type='text'
                        name='formCheck'
                        placeholder="Hint: Another Color"
                        onChange={handleChange}
                    />
                    <button>Submit</button>
                </form>
            </section>
        </Container>
    );
}

export default Contact;