import React from 'react';
import styled from 'styled-components';
import Nursing from "./images/nursingOne.jpg";
import Amenities from "./images/nursingTwo.jpg";
import Testimonial from "./images/nursingThree.jpg";

const Container = styled.div`
    width: 100vw;
    padding: 0 0 60px 0;

    .header{
        display: flex;
        justify-content: center;
        padding: 30px 0 60px 0;

        h1{
            @media(max-width: 1100px){
                font-size: 3.3rem;
                text-align: center;
            }
        }
    }

    .leftPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column;
        }


        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 500px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }

        }
    }

    .rightPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column-reverse;
        }

        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 425px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }
            
        }
    }
`;

const NursingCare = (props) => {
    return (
        <Container>
            <div className="header">
                <h1>Nursing Care at Resthave</h1>
            </div>
            <section className="rightPic">
                <div className="text">
                    <h2>Resthave's Nursing Care</h2>
                    <p>Resthave has sixty-four beautiful private rooms, two semi- private rooms and a hospice suite with bathroom and individual climate controls. Each room is furnished with a bed, dresser, night stand, window treatments, bedspread, linens, towels and large closet. All of these rooms are also available for short-term rehabilitation.</p>
                </div>
                <div className="pic">
                    <img src={Nursing} alt="Nursing Alternative"/>
                </div>
            </section>
            <section className="leftPic">
                <div className="pic">
                    <img src={Amenities} alt="Amenities Alternative"/>
                </div>
                <div className="text">
                    <h2>Amenities</h2>
                    <p>A few of the amenities you will find in our Nursing Care rooms include medication assistance and whirlpool access, as well as a nurse on-site 24 hours a day. For more amenities, please visit our amenities page.</p>
                </div>
            </section>
            <section className="rightPic">
                <div className="text">
                    <h2>Testimonial</h2>
                    <p>“The positive attitude and changes in our family member since she moved to Resthave are a true testimony to the attention and care of the medical and professional staff. We are so happy she found a home where she and we feel at ease and grew into her new normal. Thank you!” ~from nursing family satisfaction survey.</p>
                </div>
                <div className="pic">
                    <img src={Testimonial}  alt="Testimonial Alternative"/>
                </div>
            </section>
        </Container>
    );
}

export default NursingCare;