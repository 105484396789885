import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWheelchair } from '@fortawesome/free-solid-svg-icons'
import Housing from './equal-housing.jpg'
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;


    .top {
        display: flex;
        width: 75%;
        justify-content: space-around;
        margin: 20px 0;
        text-align: center;

        @media(max-width: 600px){
            flex-direction: column;
        }

        section{
            display: flex;
            width: 30%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @media(max-width: 600px){
                width: 100%;
            }

            h2{
                font-size: 30px;
                text-align: center;
            }

            a {
                font-size: 22px;
                text-decoration: none;
                color: #000;
                margin: 10px 0;
            }
        }
    }

    .bottom{
        text-align: center;

        .eop{
            margin: 10px 0;
            font-size: 20px;

            img{
                width: 20px;
            }
        }

        .facebook{
            margin: 10px 0;
            font-size: 30px;

            a{
                text-decoration: none;
            }
        }

        .copyright{
            margin: 10px 0;
        }

        .poweredBy{
            margin: 10px 0;
            font-size: 12px;

            a{
                text-decoration: none;
                color: #000;
            }
        }
    }
`;

const Footer = (props) => {
    return (
        <Container>
            <div className="top">
                <section>
                    <h2>About Resthave</h2>
                    <Link to="/">Home Page</Link>
                    <Link to="/about">About Page</Link>
                    <a href="https://www.facebook.com/resthavehome" target="_blank">Facebook</a>
                </section>
                <section>
                    <h2>For Families</h2>
                    <a href="tel:+18157724021">815-772-4021</a>
                    <Link to="/donate">Donate Page</Link>
                    <Link to="/newsletters">Newsletters</Link>
                </section>
                <section>
                    <h2>Care Options</h2>
                    <Link to="/skilled_care">Skilled Care</Link>
                    <Link to="/nursing_care">Nursing Care</Link>
                    <Link to="/assisted_living">Assisted Living</Link>
                </section>
            </div>
            <div className="bottom">
                <div className="eop">
                    <p><img src={Housing} alt="Housing Alternative"/>Resthave Home is an equal opportunity provider and employer<FontAwesomeIcon icon={faWheelchair} /></p>
                </div>
                <div className="copyright">
                    <p>© 2020-{new Date().getFullYear()}. All rights reserved. Resthave Home of Whiteside County of Illinois</p>
                </div>
                <div className="poweredBy">
                    <a href="https://www.dirtroad.dev" target="_blank">Powered by Dirt Road Dev</a>
                </div>
            </div>
        </Container>
    );
}

export default Footer;