import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    border: 1px solid black;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 0;
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    @media(max-width: 1100px){
        width: 80%;
    }

    h2{
        font-size: 2.5rem;
        margin: 10px 0;
        text-align: center;

        @media(max-width: 1100px){
            font-size: 4rem;
        }

        @media(max-width: 500px){
            font-size: 3rem;
        }
    }

    p{
        font-size: 1.4rem;
        text-align: center;
        margin: 10px 0;

        @media(max-width: 1100px){
            font-size: 2.5rem;
        }

        @media(max-width: 600px){
            font-size: 2rem;
        }
    }

    a{
        text-decoration: none;
        font-size: 1.5rem;
        margin: 10px 0;

        @media(max-width: 1100px){
            font-size: 2.5rem;
        }

        @media(max-width: 600px){
            font-size: 2rem;
        }
    }
`;

const Option = (props) => {
    return (
        <Container>
            <h2>{props.header}</h2>
            <p>{props.p}</p>
            <Link to={props.link}>Learn More</Link>
        </Container>
    );
}

export default Option;