import React, { useEffect } from 'react';
import styled from 'styled-components';
import Option from './option';
import Hero from './images/newHeroImage2020.jpg';
import Logo from './images/newLogoV2White.png';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import LeftSideHomeImage from '../../images/ihca_banner.jpg';
import RightSideHomeImage from '../../images/sauk.jpg';

ReactGA.initialize('UA-101155498-7');

// const LeftSideHomeImage = RewardBanner;

const Container = styled.div`
    width: 100vw;
    height: auto;
    // Not being used currently
    .hero{
        width: 100vw;
        min-height: 80vh;
        height: auto;
        // background: url(${LeftSideHomeImage});
        // background-position: center;
        // background-size: contain;
        margin-bottom: 15px;
        // background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        img{
            width: 75%;
            @media(max-width: 1100px){
                width: 70%;
            }
        }

        @media(max-width: 1100px){
            height: 40vh;
        }
    }

    // Annual Meeting Temporary CSS
    .annualMeeting {
        width: 100%;
        height: auto;
        padding: 16px;
        margin: 16px auto;
        display: flex;
        border-radius: 15px;
        flex-direction: column;
        align-items: center;
        .annualMeetingContentPara {
            width: 85%;
            p {
                font-size: 1.15rem;
            }
        }
        .topBar {
            p {
                text-align: center;
                color: black;
                font-size: 1.25rem;
                border-bottom: 1px solid white;
                padding: 25px;
                margin: 10px 25px;
            }
        }
        img {
            margin: 16px 0;
            height: 350px;

            @media(max-width: 1024px) {
                height: 275px;
            }

            @media(max-width: 864px) {
                height: 200px
            }

            @media(max-width: 768px) {
                height: 150px;
            }
        }
        p {
            @media(max-width: 800px) {
                width: 85%;
            }
            width: 100%;
            text-align: center;
            color: #000;
            font-size: 1.5rem;
        }
        .buttonToAnnualMeeting {
            color: white;
            background-color: rgb(4, 44, 109);
            border-radius: 15px;
            width: 35%;
            text-align: center;
            padding: 16px;
            height: 50px;
        }

    }
    .options{
        width: 100%;
        display: flex;
        flex-direction: column;

        .header{
            width: 100%;
            height: 80px;
            background: rgb(4, 44, 109);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            h2{
                font-size: 3rem;
                text-align: center;

                @media(max-width: 600px){
                    font-size: 2rem;
                }
            }
        }

        .list{
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 50px 0 50px 0;

            @media(max-width: 1100px){
                flex-direction: column;
                align-items: center;
            }
        }
    }
`;

const Home = (props) => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <Container>
            <div className='annualMeeting'>
                <img src="/assets/logo.svg" alt="Logo for Resthave Home Page" />
                
            </div>
            <div className="options">
                <div className="header">
                    <h2>Care Options at Resthave</h2>
                </div>
                <div className="list">
                    <Option header="Skilled Care" p="We provide twenty-four hour skilled care to Medicare and Insurance recipients who require daily skilled services." link="/skilled_care" />
                    <Option header="Nursing Care" p="We provide twenty-four hour nursing care in our nursing wing. Our nursing staff assists with activities of daily living as well as other aspects of their medical needs." link="/nursing_care" />
                    <Option header="Assisted Living" p="We provide twenty-four hour assisted living services in regular and XL one bedroom apartments." link="/assisted_living" />
                </div>
            </div>
        </Container>
    );
}

export default Home;