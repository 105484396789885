import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from './components/navbar';

import About from './components/about';
import Admissions from './components/admissions';
import Amenities from './components/amenities';
import AssistedLiving from './components/assisted_living';
import Contact from './components/contact';
import Donate from './components/donate';
import Home from './components/home';
import Newsletters from './components/newsletters';
import NursingCare from './components/nursing_care';
import Opportunities from './components/opportunities';
import SkilledCare from './components/skilled_care';
import Footer from './components/footer';
import AnnualMeeting from './components/annual_meeting';
import CardiacPulmonary from './components/cardiac_pulmonary';

function App() {


  
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/admissions" component={Admissions} />
          <Route exact path="/amenities" component={Amenities} />
          <Route exact path="/assisted_living" component={AssistedLiving} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/donate" component={Donate} />
          <Route exact path="/newsletters" component={Newsletters} />
          <Route exact path="/nursing_care" component={NursingCare} />
          <Route exact path="/opportunities" component={Opportunities} />
          <Route exact path="/skilled_care" component={SkilledCare} />
          <Route exact path='/annual_meeting' component={AnnualMeeting} />
          <Route exact path='/cardiac_pulmonary' component={CardiacPulmonary} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
