import React, { useState } from 'react';
import styled from 'styled-components';
import Image from './images/oppoOne.jpg';
import Application from './pdfs/Resthave_Home_Job_English.pdf';
import axios from 'axios';
import { API } from '../url';
const Container = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;    

    .hero{
        width: 100vw;
        height: 80vh;
        background-image: url(${Image});
        background-position: bottom right;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media(max-width: 1100px){
            height: 40vh;
        }

        h1{
            font-size: 3rem;
            text-align: center;
        }

        p{
            font-size: 1.8rem;
            width: 50%;
            text-align: center;
            margin: 0;

            @media(max-width: 1100px){
                font-size: 1.3rem;
            }

            @media(max-width: 800px){
                display: none
            }
        }

    }

    .sections{
        width: 100%;
        display: flex;
        padding: 40px 0;

        @media(max-width: 1100px){
            flex-direction: column;
            align-items: center;
        }

        .positions{
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media(max-width: 1100px){
                width: 90%;
            }

            h2{
                font-size: 2.5rem;
            }

            p{
                text-align: center;
                font-size: 1.5rem;
                margin: 20px;
                width: 80%;
            }

            .list{

                p{
                    margin: 0;
                }
            }

            a{
                text-decoration: none;
                font-size: 1.3rem;
                margin: 10px 0;
            }
        }

        .form{
            width: 40%;

            @media(max-width: 1100px){
                width: 90%;
            }

            form{
                width: 80%;
                display: flex;
                flex-direction: column;

                @media(max-width: 1100px){
                    width: 100%;
                }

                label{
                    font-size: 1.4rem;
                    color: black;
                }
    
                input{
                    font-size: 1.4rem;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    border: 1px solid black;
                    width: 100%;
                }
    
                textarea{
                    font-size: 1.4rem;
                    margin-bottom: 30px;
                    height: 150px;
                    border-radius: 5px;
                    border: 1px solid black;
                    width: 100%;
                }
    
                button{
                    font-size: 1.4rem;
                    background: rgb(4, 44, 109);
                    color: white;
                    height: 50px;
                    border-radius: 5px;
                    border: 1px solid black;
                }
            }
        }
    }
`;

const Opportunities = (props) => {

    const [data, setData] = useState({
        name_field: '',
        email_field: '',
        message_field: '',
        formCheck: ''
    })

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const submit = (e) => {
        e.preventDefault();
        axios
            .post(`${API}/resthave-volunteer-contact`, data)
            // .post(`https://www.resthave.org/resthave-volunteer-contact`, data)
            .then(res => {
                console.log("it worked!")
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Container>
            <section className="hero">
                <h1>Opportunities at Resthave</h1>
                <p>Resthave has many opportunities for both employment and volunteers. Use the form below to volunteer, or fill out the PDF and email us to apply for a job.</p>
            </section>
            <div className="sections">
                <section className="positions">
                    <h2>Apply at Resthave</h2>
                    <p>Resthave is an equal opportunity employer. We are always looking for great full time and part time employees.</p>
                    <p>All positions are welcome to apply including:</p>
                    <div className="list">
                        <p>RN's</p>
                        <p>CNA's</p>
                        <p>LPN's</p>
                        <p>And all other positions</p>
                    </div>
                    <a href={Application} target="_blank" rel="noopener noreferrer">Click here for an employment application</a>
                </section>
                <section className="form">
                    <form onSubmit={submit}>
                        <label htmlFor="name">Name</label>
                        <input
                            type='text'
                            name='name_field'
                            onChange={handleChange}
                        />
                        <label htmlFor="email">Email</label>
                        <input
                            type='text'
                            name='email_field'
                            onChange={handleChange}
                        />
                        <label htmlFor="message">Message</label>
                        <textarea
                            type='text'
                            name='message_field'
                            onChange={handleChange}
                        />
                        <label htmlFor="verify">Please prove that your a human. A Zebra is Black and ?????</label>
                        <input
                            type='text'
                            name='formCheck'
                            placeholder="Hint: Another Color"
                            onChange={handleChange}
                        />
                        <button>Submit</button>
                    </form>
                </section>
            </div>
        </Container>
    );
}

export default Opportunities;