import React from 'react';
import styled from 'styled-components';
import Dec23 from "./pdfs/december_23.pdf";
import March23 from "./pdfs/march_23.pdf";
import June23 from "./pdfs/june_23.pdf";
import Sept23 from "./pdfs/september_23.pdf";
import June24 from "./pdfs/june_24.pdf";
import Sept24 from "./pdfs/september_24.pdf";

const Container = styled.div`
    width: 100vw;

    .hero{
        width: 100vw;
        height: 30vh;
        background: rgb(4, 44, 109);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;

    }

    .newsletters{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px 0 100px 0;

        p{
            font-size: 1.7rem;
            padding: 10px 0;
        }

        a{
            text-decoration: none;
        }
    }
`;

const Newsletters = (props) => {
    return (
        <Container>
            <div className="hero">
                <h1>The Resthave Monthly Newsletters</h1>
            </div>
            <div className="newsletters">
                <p>Newsletter for <a href={Sept24} target="_blank" rel="noopener noreferrer">September 2024</a></p>
                <p>Newsletter for <a href={June24} target="_blank" rel="noopener noreferrer">June 2024</a></p>
                <p>Newsletter for <a href={Dec23} target="_blank" rel="noopener noreferrer">December 2023</a></p>
                <p>Newsletter for <a href={Sept23} target="_blank" rel="noopener noreferrer">September 2023</a></p>
                <p>Newsletter for <a href={June23} target="_blank" rel="noopener noreferrer">June 2023</a></p>
                <p>Newsletter for <a href={March23} target="_blank" rel="noopener noreferrer">March 2023</a></p>
                {/* <p>Newsletter for <a href={Dec22} target="_blank" rel="noopener noreferrer">December 2022</a></p>
                <p>Newsletter for <a href={Sept22} target="_blank" rel="noopener noreferrer">September 2022</a></p>
                <p>Newsletter for <a href={June22} target="_blank" rel="noopener noreferrer">June 2022</a></p>
                <p>Newsletter for <a href={March22} target="_blank" rel="noopener noreferrer">March 2022</a></p>
                <p>Newsletter for <a href={Dec21} target="_blank" rel="noopener noreferrer">December 2021</a></p>
                <p>Newsletter for <a href={Sept21} target="_blank" rel="noopener noreferrer">September 2021</a></p>
                <p>Newsletter for <a href={June21} target="_blank" rel="noopener noreferrer">June 2021</a></p>
                <p>Newsletter for <a href={March21} target="_blank" rel="noopener noreferrer">March 2021</a></p>
                <p>Newsletter for <a href={Dec20} target="_blank" rel="noopener noreferrer">December 2020</a></p>
                <p>Newsletter for <a href={June20} target="_blank" rel="noopener noreferrer">June 2020</a></p>
                <p>Newsletter for <a href={March20} target="_blank" rel="noopener noreferrer">March 2020</a></p>
                <p>Newsletter for <a href={Dec19} target="_blank" rel="noopener noreferrer">December 2019</a></p>
                <p>Newsletter for <a href={Sept19} target="_blank" rel="noopener noreferrer">September 2019</a></p>
                <p>Newsletter for <a href={March19} target="_blank" rel="noopener noreferrer">March 2019</a></p> */}

            </div>
        </Container>
    );
}

export default Newsletters;
