import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from './button';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
    width: 100vw;
    height: 160px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 0 0 25px 0;

    .topBar{
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: black;
        color: white;

        a{
            color: white;
        }

        p{
            margin: 0;
        }
        @media(max-width: 800px) {
            p {
                font-size: 8px;
            }
        }
    }

    .top{
        width: 100%;
        height: 100px;
        background: rgb(4, 44, 109);
        display: flex;

        .logo{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width: 900px){
                width: 100%;
            }

            h1{
                font-family: "Prata", serif;
                color: #FBFBFC;
                font-size: 35px;
                font-weight: 500;
                line-height: 1.2;
                text-align: center;

                @media(max-width: 900px){
                    font-size: 3rem;
                }

                @media(max-width: 600px){
                    font-size: 2rem;
                }
            }
        }

        .buttons{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .annualMeeting {
                width: auto;
                height: 50px;
                background: #FBFBFC;
                border-radius: 15px;
                // margin: 0 20px;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: black;
                font-weight: 600;
                padding: 0 10px 0 10px;
                @media(max-width: 800px) {
                    font-size: 12px;
                    text-align: center;
                }
            }

        }
    }

    .bottom{
        width: 100%;
        height: 60px;
        background: #FBFBFC;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        @media(max-width: 850px){
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .hamburger{
            display: none;

            @media(max-width: 850px){
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .routes{
            width: 55%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.5rem;

            @media(max-width: 850px){
                width: 100%;
                display: none;
            }

            .item{
                margin: 0 20px;
                a{
                    text-decoration: none;
                    color: grey;
                }
            }
        }
    }
`;

const Navbar = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Container>
            {/* <div className="topBar"> */}
            {/*     <p>Please <a style={{ color: 'silver' }}href='../../assets/Annual%20Board%20Meeting%20Report%202022.pdf'>click here</a> to access the annual board meeting writeup</p> */}
            {/* </div> */}
            <div className="top">
                {/* <div className="logo"> */}
                {/*     <h1>Resthave Care and Rehabilitation</h1> */}
                {/* </div> */}
                <div className="buttons">
                    <Button linkto="tel:+18157724021" text="Call Resthave" route="" />
                    <Link className='annualMeeting' to='/annual_meeting'>Annual Meeting Notice</Link>
                    <Button linkto="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=NhTCXgfwQIaCH8GnGCP6JgAAAW%2FEc3UW#!/payment-method" text="Donate Now" route="" />
             </div>
            </div>
            <div className="bottom">
                <Dropdown className="hamburger" isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle style={{ background: "#FBFBFC", border: '1px solid #FBFBFC', color: 'grey', fontSize: '1.5rem' }}>
                        <FontAwesomeIcon icon={faBars} />
                    </DropdownToggle>
                    <DropdownMenu style={{ background: "#FBFBFC", border: '1px solid #FBFBFC', color: 'grey', fontSize: '1.5rem' }}>
                        <DropdownItem>
                            <Link to="/" style={{ color: 'grey' }} >Home</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/about" style={{ color: 'grey' }} >About</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/skilled_care" style={{ color: 'grey' }} >Skilled Care</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/nursing_care" style={{ color: 'grey' }} >Nursing Care</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/assisted_living" style={{ color: 'grey' }} >Assisted Living</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/amenities" style={{ color: 'grey' }} >Amenities</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/opportunities" style={{ color: 'grey' }} >Opportunities</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/admissions" style={{ color: 'grey' }} >Admissions</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/contact" style={{ color: 'grey' }} >Contact</Link>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div className="routes">
                    <div className="item">
                        <Link to='/'>Home</Link>
                    </div>
                    <div className="item">
                        <Link to='/about'>About</Link>
                    </div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle style={{ background: "#FBFBFC", border: '1px solid #FBFBFC', color: 'grey', fontSize: '1.5rem' }} caret>
                            Care Options
                            </DropdownToggle>
                        <DropdownMenu style={{ background: "#FBFBFC", border: '1px solid #FBFBFC', color: 'grey', fontSize: '1.5rem' }}>
                            <DropdownItem style={{ background: "#FBFBFC", border: '1px solid #FBFBFC', color: 'grey', fontSize: '1.5rem' }}>
                                <Link to="/skilled_care" style={{ color: 'grey' }} >Skilled Care</Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link to="/nursing_care" style={{ color: 'grey' }} >Nursing Care</Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link to="/assisted_living" style={{ color: 'grey' }} >Assisted Living</Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <div className="item">
                        <Link to='/amenities'>Amenities</Link>
                    </div>
                    <div className="item">
                        <Link to='opportunities'>Opportunities</Link>
                    </div>
                    <div className="item">
                        <Link to='admissions'>Admissions</Link>
                    </div>
                    <div className="item">
                        <Link to='contact'>Contact</Link>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Navbar;
