import React from 'react';
import styled from 'styled-components';
import Occupational from './images/therapy.jpg';
import Speech from './images/skilledOne.jpg';
import Testimonial from './images/skilledTwo.jpg';

const Container = styled.div`
    width: 100vw;

    .header{
        display: flex;
        justify-content: center;
        padding: 30px;

        h1{
            @media(max-width: 1100px){
                font-size: 3.3rem;
                text-align: center;
            }
        }
    }

    .double{
        display: flex;
        justify-content: space-around;
        padding: 35px;

        @media(max-width: 1100px){
            flex-direction: column;
            align-items: center;
        }

        .single{
            width: 40%;

            @media(max-width: 1100px){
                width: 90%;
            }

            h2{
                font-size: 2rem;
                text-align: center;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                text-align: center;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }
        }
    }

    .leftPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column;
        }

        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 500px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }

        }
    }

    .rightPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column-reverse;
        }

        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 425px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }
            }
            
        }
    }

`;

const SkilledCare = (props) => {
    return (
        <Container>
            <div className="header">
                <h1>Skilled Care at Resthave</h1>
            </div>
            <div className="double">
                <div className="single">
                    <h2>Our Care Program</h2>
                    <p>Selecting the best rehabilitation / skilled care can be a difficult decision. Whether it is an illness, surgery or injury, Resthave is here to help. Our excellent therapy team is here to assist you in reaching your goals and get you back home quickly.</p>
                </div>
                <div className="single">
                    <h2>Physical Therapy</h2>
                    <p>We offer strengthening exercises, therapeutic ultrasound, electrical stimulation treatments and more. Physical therapy can help with joint pain, wounds, joint replacements, acute and chronic pain management, amputations, mobility training, balance techniques, strokes, fractures and orthopedic conditions, spinal cord injuries, neuromuscular injuries and conditions, acquired and traumatic brain injuries just to name a few. We also offer home evaluations and safety awareness services.</p>
                </div>
            </div>
            <div className="leftPic">
                <div className="pic">
                    <img src={Occupational}  alt="Occupational Alternative"/>
                </div>
                <div className="text">
                    <h2>Occupational Therapy</h2>
                    <p>Our knowledgeable occupational therapy team will work with you to help with activities of daily living skills to manage daily tasks such as eating, dressing, bathing home management and community activities. We can teach you compensatory techniques for losses of strength, cognition, endurance, vision, sensation, coordination, balance and more. You will benefit from working with our team on conditions such as arthritis, Alzheimer’s and other dementias, burns, stroke or heart attack limitations, fractures, balance issues, spinal cord injuries, vision problems, cognitive problems and progressive neurological diseases such as Parkinsons.</p>
                </div>
            </div>
            <div className="rightPic">
                <div className="text">
                    <h2>Speech Therapy</h2>
                    <p>The caring speech therapy team at Resthave can help you with articulation, voice, memory, thinking and processing, tracheostomy, dysphagia management (swallowing therapy), expressive and receptive language skills and fluency (stuttering) conditions. We also offer caregiver training in compensatory techniques for communication and swallowing.</p>
                </div>
                <div className="pic">
                    <img src={Speech}  alt="Speech Alternative"/>
                </div>
            </div>
            <div className="leftPic">
                <div className="pic">
                    <img src={Testimonial}  alt="Testimonial Alternative"/>
                </div>
                <div className="text">
                    <h2>Testimonial</h2>
                    <p>“I Love My Resthave Family...Everything is great here. The food, the courtyard, activities, and especially the staff. The staff is amazing and makes all the difference. I tell everyone how happy I am that I chose Resthave for skilled care. We are truly blessed to have a facility like Resthave so close to home.” ~Gloria DeValle</p>
                </div>
            </div>
        </Container>
    );
}

export default SkilledCare;