import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    background: rgb(4, 44, 109);
    color: white;
    padding: 40px;

    .header{
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            font-size: 2.5rem;
            text-align: center;
        }

        p{
            width: 70%;
            text-align: center;
            font-size: 1.5rem;

            @media(max-width: 800px){
                width: 90%;
            }
        }

    }

    .lists{
        width: 100%;
        display: flex;
        justify-content: center;

        ul{

            li{
                font-size: 1.3rem;

            }
        }
    }

    .bottom{
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            font-size: 1.5rem;
        }

        a{
            width: 230px;
            height: 50px;
            font-size: 1.5rem;
            border-radius: 20px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            margin: 20px 0;
        }
    }

`;

const Donate = (props) => {
    return (
        <Container>
            <section className="header">
                <h1>Charitable Giving Opportunities</h1>
                <p>Caring for others is at the heart of who we are at Resthave. Your generosity helps to support our mission of providing compassionate care that uplifts human wholeness, dignity, respect, peace, and tranquility, even at the end of life. There are a variety of ways that you can give to Resthave, a 501(c)(3) not-for-profit, including:</p>
            </section>
            <section className="lists">
                <ul>
                    <li>Monetary donations</li>
                    <li>Memorial designations at a funeral</li>
                    <li>Charitable gift annuities</li>
                    <li>Retained life estates</li>
                    <li>Homes</li>
                    <li>Insurance or retirement beneficiary designations</li>
                    <li>Donations in honor/memory/celebration</li>
                    <li>Gifts of stocks, and/or real estate</li>
                    <li>Charitable trusts</li>
                    <li>Endowment funds</li>
                    <li>Vehicles</li>
                </ul>
            </section>
            <section className="bottom">
                <p>If you are interested in giving, please fill out the pledge form and a member of Resthave will be in touch with you.</p>
                <p>Thank you for your consideration in helping us to sustain Resthave’s mission and philosophy of care.</p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=NhTCXgfwQIaCH8GnGCP6JgAAAW%2FEc3UW">Donate Now</a>
            </section>
        </Container>
    );
}

export default Donate;