import React from 'react';
import styled from 'styled-components';
import CardiacPulmonaryImage from './images/Picture1.jpg';

// import Occupational from './images/therapy.jpg';
// import Speech from './images/skilledOne.jpg';
// import Testimonial from './images/skilledTwo.jpg';

const Container = styled.div`
    width: 100vw;

    .header{
        display: flex;
        justify-content: center;
        padding: 30px;

        h1{
            @media(max-width: 1100px){
                font-size: 3.3rem;
                text-align: center;
            }
        }
    }

    .double{
        display: flex;
        justify-content: space-around;
        padding: 35px;

        @media(max-width: 1100px){
            flex-direction: column;
            align-items: center;
        }

        .single{
            width: 40%;

            @media(max-width: 1100px){
                width: 90%;
            }

            h2{
                font-size: 2rem;
                text-align: center;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                text-align: center;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }
        }
    }

    .leftPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column;
        }

        .pic {
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 500px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }

        }
    }

    .rightPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column-reverse;
        }

        .pic{
            width: 40%;
            height: auto;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                border-radius: 20px;
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 425px;

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 70%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }
            }
            
        }
    }
    .benefits {
        width: 85%;
        margin: 0 auto;
        h3 {
            font-size: 2rem;
            border-bottom: 2px solid rgb(4, 44, 109);
            padding: 5px;
        }
        ul {
            margin-left: 2.5%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-evenly;
            flex-wrap: wrap;
            li {
                width: 42.5%;
                font-size: 1.35rem;
                font-weight: 300;
            }
        }
    }

    .contact {
        width: 85%;
        margin: 0 auto;
        text-align: center;
        border-top: 2px solid rgb(4, 44, 109);
        h4 {
            padding: 5px;
            a {
                color: rgb(4, 44, 109);
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }

`;

const CardiacPulmonary = (props) => {
    return (
        <Container>
            <div className="header">
                <h1>Cardiac &amp; Pulmonary Center of Excellence Programs</h1>
            </div>
            <div className="rightPic">
                <div className="text">
                    <p>Resthave Care and Rehabilitation is pleased to announce that we are teaming up with Dr. Jeremy Berg, Pulmonologist – Alteas Health, Dr. Joel Okner, Cardiologist and Integrated Respiratory Solutions to provide Cardiac Pulmonary program to our residents.  Our Cardiac Pulmonary Center of Excellence Program will integrate with physical therapy, occupational therapy and speech therapy to create a rehabilitation and wellness delivery model.</p>
                    <p>Residents participating in the program will be provided a cardiac and pulmonary consultation, respiratory/pulmonary rehabilitation services provided by a licensed, registered respiratory therapist and utilization of advanced clinical modalities including oxygen therapy, aerosol delivery, airway care, respiratory care applications and modalities, pulmonary diagnostic and pulmonary rehabilitation techniques.</p>
                {/* </div>
                    <p>Selecting the best rehabilitation / skilled care can be a difficult decision. Whether it is an illness, surgery or injury, Resthave is here to help. Our excellent therapy team is here to assist you in reaching your goals and get you back home quickly.</p> */}
                </div>
                <div className="pic">
                    <img src={CardiacPulmonaryImage} alt="Cardiac Pulmonary" />
                    {/* <h2>Physical Therapy</h2>
                    <p>We offer strengthening exercises, therapeutic ultrasound, electrical stimulation treatments and more. Physical therapy can help with joint pain, wounds, joint replacements, acute and chronic pain management, amputations, mobility training, balance techniques, strokes, fractures and orthopedic conditions, spinal cord injuries, neuromuscular injuries and conditions, acquired and traumatic brain injuries just to name a few. We also offer home evaluations and safety awareness services.</p> */}
                </div>
            </div>
            <div className='benefits'>
                <h3>Program Benefits</h3>
                <ul>
                    <li>Symptom relief</li>
                    <li>Improved exercise tolerance</li>
                    <li>Increased quality of life</li>
                    <li>Lower healthcare utilization</li>
                    <li>Early intervention </li>
                    <li>Associated education  </li>
                    <li>Evaluation tools so residents and direct care staff can monitor progress</li>
                    <li>Continuity of care</li>
                </ul>
            </div>
            <div className='contact'>
                <h4>For more information, please contact our Administrator, Director of Nursing, or Marketing Director at <a href="tel:+8157724021">815-772-4021</a></h4>
            </div>
        </Container>
    );
}

export default CardiacPulmonary;