import React from 'react';
import styled from 'styled-components';
import Bedroom from './images/assistedLivingBedroom.jpg';
import XLBedroom from './images/assistedOne.jpg';
import Amenities from './images/assistedTwo.jpg';
import Testimonial from './images/assistedThree.jpg';

const Container = styled.div`
    width: 100vw;
    padding: 30px 0 60px 0;

    .header{
        display: flex;
        justify-content: center;
        padding: 30px;

        h1{

        }
    }

    .double{
        display: flex;
        justify-content: space-around;
        padding: 35px;

        .single{
            width: 40%;

            h2{
                font-size: 2rem;
                text-align: center;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                text-align: center;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }
        }
    }

    .leftPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column;
        }

        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 500px;

                @media(max-width: 1100px){
                    height: 100%;
                    width: 100%;
                }

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 80%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }

        }
    }

    .rightPic{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;

        @media(max-width: 1100px){
            flex-direction: column-reverse;
        }

        .pic{
            width: 40%;
            height: 500px;
            overflow: hidden;
            border-radius: 20px;

            @media(max-width: 1100px){
                width: 70%;
                height: 400px;
            }

            @media(max-width: 600px){
                height: 200px;
            }

            img{
                max-width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 500px;

                @media(max-width: 1100px){
                    height: 100%;
                    width: 100%;
                }

                @media(max-width: 600px){
                    min-height: 200px;
                }
            }

        }

        .text{
            width: 40%;
            text-align: center;

            @media(max-width: 1100px){
                width: 80%;
            }

            h2{
                font-size: 2rem;
                border-bottom: 1px solid black;
                padding: 0 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.8rem;
                }

            }

            p{
                font-size: 1.5rem;
                padding: 10px 0 0 0;

                @media(max-width: 1100px){
                    font-size: 2rem;
                }

            }

        }
    }

`;

const AssistedLiving = (props) => {
    return (
        <Container>
            <div className="header">
                <h1>Assisted Living at Resthave</h1>
            </div>
            <div className="rightPic">
                <section className="text">
                    <h2>One Bedroom Apartment</h2>
                    <p>Our home is newly remodeled and decorated to feel like home. Resthave has thirty-seven beautiful apartments. Each apartment has individual climate controls, kitchenette, micro-fridge and large bathroom with walk-in shower and built in seat.</p>
                </section>
                <section className="pic">
                    <img src={Bedroom} alt="Bedroom Alternative"/>
                </section>
            </div>
            <div className="leftPic">
                <section className="pic">
                    <img src={XLBedroom} alt="XL Bedroom Alternative"/>
                </section>
                <section className="text">
                    <h2>One Bedroom XL Apartment</h2>
                    <p>Four of these rooms are extra-large rooms designed for couples. Certified nurse’s aides are available at all times to assist residents as needed. A nurse is on-site and available 24 hours a day for emergency situations.</p>
                </section>
            </div>
            <div className="rightPic">
                <section className="text">
                    <h2>Amenities</h2>
                    <p>A few of the amenities you will find in our Assisted Living rooms include patio areas, walk-in showers with grab-bars and a built-in seat, and a nurse on-site 24 hours a day. For more amenities, please visit our amenities page.</p>
                </section>
                <section className="pic">
                    <img src={Amenities}  alt="Amenities Alternative"/>
                </section>
            </div>
            <div className="leftPic">
                <section className="pic">
                    <img src={Testimonial}  alt="Testimonial Alternative"/>
                </section>
                <section className="text">
                    <h2>Testimonial</h2>
                    <p>“I loved what I saw... I moved to Resthave 10 months ago to be closer to my daughter and I’m so glad I did. Everyone including the staff and residents are genuine and friendly. They made me feel at home right away. I have had great conversations with new friends. There are activities every day and I especially enjoy fitness. The food is good and you have a say in what you eat which is a plus. I loved what I saw the minute I walked in the building. The facility is clean and bright. The staff is very nice and care about you.” ~ Tess Zilly</p>
                </section>
            </div>
        </Container>
    );
}

export default AssistedLiving;