import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

// Lazy Loading Images
import First from './images/one.png';
import September from './images/september.png';
import October from './images/october.png';
import November from './images/november.png';
import December from './images/december.png';
import January from './images/january.png';
import February from './images/february.png';
import March from './images/march.png';
import April from './images/april.png';
import AprilC from './images/aprilContinued.png';
import May from './images/may.png';
import MayC from './images/mayContinued.png';
import June from './images/june.png';
import July from './images/july.png';
import ThankYou from './images/thankYou.png';
import Final from './images/final.png';
ReactGA.initialize('UA-101155498-7');

const Container = styled.div`
	overflow-x: hidden;
	.opening {
		width: 100%;
		text-align: center;
		h2 {
			text-align: center;
			font-size: 2.5rem;
			margin: 5% 0 0 0;
			padding: 0;
		}
	}
	.slides {
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-around;
		.slide {
			width: 85%;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			img {
				width: auto;
				height: 85vh;
				margin: 0 auto;
				padding: 0;
                @media(max-width: 800px) {
                    width: 85vw;
                    height: auto;
                }
			}
		}
	}
	.financials {
		padding: 0;
		margin: 0;
		width: 100%;
		.fSection {
			width: 100%;
			margin: 0;
			padding: 0;
			h3 {
				text-align: left;
				margin-left: 5%;
                @media(max-width: 800px) {
                    font-size: 1.2rem;
                    margin-top: 15px;
                }
			}
			.row:nth-child(odd) {
				background-color: lightgrey;
			}
			.head {
				background-color: black;
				color: white;
			}
			.tbl {
				display: flex;
				flex-direction: row;
				flex-wrap: none;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 30px;
				padding: 0;
				margin: 0;
				p {
					height: 30px;
					width: 20%;
					text-align: center;
					padding: 5px 0 5px 0;
					margin: 0;
                    @media(max-width: 800px) {
                        font-size: 8px;
                    }
				}

			}
		}
	}
`;

const AnnualMeeting = (props) => {
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    const stats = {
    	census: [
    		{
    			rowTitle: 'Nursing Home',
    			one: '21,626',
    			two: '21,758',
    			three: '23,321',
    			four: '22,824'
    		},
    		{
    			rowTitle: 'Assisted Living',
    			one: '8,626',
    			two: '12,152',
    			three: '13,748',
    			four: '13,344'
    		},
    		{
    			rowTitle: 'Total',
    			one: '30,262',
    			two: '33,910',
    			three: '37,069',
    			four: '36,168'
    		},
    		{
    			rowTitle: 'Average Daily Census',
    			one: '82.91',
    			two: '92.90',
    			three: '101.56',
    			four: '99.09'
    		},
    		{
    			rowTitle: 'Available Units',
    			one: '107.00',
    			two: '107.00',
    			three: '107.00',
    			four: '107.00'
    		},
    		{
    			rowTitle: 'Occupancy Percentage',
    			one: '77.49%',
    			two: '86.83%',
    			three: '94.91%',
    			four: '92.61%'
    		}
    	],
        balance: {
            assets: [
                {
                    rowTitle: 'Cash',
                    one: '(84,835)',
                    two: '505,043',
                    three: '1,270,166',
                    four: '3,027,519'
                },
                {
                    rowTitle: 'Accounts Receivable',
                    one: '820,015',
                    two: '711,721',
                    three: '708,949',
                    four: '504,372'
                },
                {
                    rowTitle: 'Inventory',
                    one: '11,629',
                    two: '8,062',
                    three: '8,062',
                    four: '8,062'
                },
                {
                    rowTitle: 'Prepaid',
                    one: '24,490',
                    two: '2,608',
                    three: '16,024',
                    four: '26,292'
                },
                {
                    rowTitle: 'Investments',
                    one: '374,702',
                    two: '-',
                    three: '-',
                    four: '-'
                },
                {
                    rowTitle: 'Property, Plant, & Equipment',
                    one: '13,470,234',
                    two: '13,029,163',
                    three: '12,588,667',
                    four: '12,164,080'
                },
                {
                    rowTitle: 'Other Assets',
                    one: '46,625',
                    two: '45,311',
                    three: '44,144',
                    four: '42,919'
                },
                {
                    rowTitle: 'Total',
                    one: '14,662,860',
                    two: '14,301,908',
                    three: '14,636,012',
                    four: '15,773,245'
                }
            ],
            liabilities: [
                {
                    rowTitle: 'Accounts Payable',
                    one: '261,990',
                    two: '359,108',
                    three: '357,187',
                    four: '294,641'
                },
                {
                    rowTitle: 'Accrued Expenses',
                    one: '357,954',
                    two: '211,539',
                    three: '295,573',
                    four: '376,282'
                },
                {
                    rowTitle: 'Unearned Grant Revenue',
                    one: '-',
                    two: '-',
                    three: '-',
                    four: '336,445'
                },
                {
                    rowTitle: 'Other Liabilities',
                    one: '118,120',
                    two: '555,342',
                    three: '714,398',
                    four: '699,681'
                },
                {
                    rowTitle: 'Debt',
                    one: '12,847,309',
                    two: '12,765,382',
                    three: '12,547,149',
                    four: '12,333,677'
                },
                {
                    rowTitle: 'Net Assets',
                    one: '1,077,487',
                    two: '410,537',
                    three: '721,704',
                    four: '1,732,519'
                },
                {
                    rowTitle: 'Total',
                    one: '14,662,860',
                    two: '14,301,908',
                    three: '14,636,012',
                    four: '15,773,245'
                }
            ]
        },
        operations: [
            {
                rowTitle: 'Revenue',
                one: '4,953,533',
                two: '5,601,162',
                three: '6,102,873',
                four: '6,707,997'
            },
            {
                rowTitle: 'Expenses',
                one: '',
                two: '',
                three: '',
                four: ''
            },
            {
                rowTitle: 'Nursing',
                one: '1,773,955',
                two: '1,722,009',
                three: '1,760,644',
                four: '1,864,445'
            },
            {
                rowTitle: 'Marketing',
                one: '119,608',
                two: '103,588',
                three: '103,115',
                four: '110,464'
            },
            {
                rowTitle: 'Medical Records',
                one: '18,608',
                two: '19,287',
                three: '27,301',
                four: '34,594'
            },
            {
                rowTitle: 'Dietary',
                one: '545,055',
                two: '557,849',
                three: '586,042',
                four: '605,988'
            },
            {
                rowTitle: 'Housekeeping',
                one: '244,272',
                two: '257,211',
                three: '272,442',
                four: '307,007'
            },
            {
                rowTitle: 'Laundry',
                one: '2,967',
                two: '3,816',
                three: '2,181',
                four: '10,346'
            },
            {
                rowTitle: 'Plant Operations',
                one: '1,221,984',
                two: '1,946,148',
                three: '1,457,805',
                four: '1,424,036'
            },
            {
                rowTitle: 'Activities',
                one: '129,552',
                two: '124,853',
                three: '123,028',
                four: '130,721'
            },
            {
                rowTitle: 'Social Services',
                one: '93,548',
                two: '95,801',
                three: '104,733',
                four: '136,271'
            },
            {
                rowTitle: 'Purchased Services',
                one: '247,424',
                two: '575,495',
                three: '615,866',
                four: '645,595'
            },
            {
                rowTitle: 'General and Administrative',
                one: '1,074,319',
                two: '936,066',
                three: '909,529',
                four: '830,290'
            },
            {
                rowTitle: 'Total Expenses',
                one: '5,471,292',
                two: '6,342,123',
                three: '5,962,686',
                four: '6,099,757'
            },
            {
                rowTitle: 'Net Income (Loss) from Operations',
                one: '(517,759)',
                two: '(740,961)',
                three: '140,187',
                four: '608,240'
            },
            {
                rowTitle: 'Other Income (Expense)',
                one: '20,106',
                two: '53,569',
                three: '170,980',
                four: '402,574'
            },
            {
                rowTitle: 'Net Income (Loss)',
                one: '(497,653)',
                two: '(687,392)',
                three: '311,167',
                four: '1,010,814'
            }
        ]
    }

    return (
        <Container>
        	<section className='opening'>
        		<h2>Resthave Annual Meeting Information</h2>
                <p>Due to the COVID-19 emergency and out of concern for our residents, staff and our community, Resthave Care and Rehabilitation regrettably will be postponing our annual meeting until a time when it is safe to meet in person and deliver our year-end message.  Although these times are concerning for everyone, Resthave continues to go to great lengths to ensure the health and safety of all of our residents, staff and our facility partners.  As such, we definitely look forward to a time in the future where we are all able to congregate again and share our message with our community.</p>
        		<p>Please find some financials highlights and our 2020 year in review below.</p>
        	</section>
        	<section className='financials'>
        		<div className='fSection census'>
        			<h3>Census</h3>
        			<div className='tbl head'>
        				<p></p>
        				<p>YE 08/31/17</p>
        				<p>YE 08/31/18</p>
        				<p>YE 08/31/19</p>
        				<p>YE 08/31/20</p>
        			</div>
        			{stats.census.map(i => {
        				 return (
    				         <div className='tbl row'>
    				         	<p>{i.rowTitle}</p>
		        				 <p>{i.one}</p>
		        				 <p>{i.two}</p>
		        				 <p>{i.three}</p>
		        				 <p>{i.four}</p>
    				         </div>
				         );
        			})}
        		</div>
                <div className='fSection assets'>
                    <h3>Balance Sheet - Assets</h3>
                    <div className='tbl head'>
                        <p></p>
                        <p>YE 08/31/17</p>
                        <p>YE 08/31/18</p>
                        <p>YE 08/31/19</p>
                        <p>YE 08/31/20</p>
                    </div>
                    {stats.balance.assets.map(i => {
                         return (
                             <div className='tbl row'>
                                <p>{i.rowTitle}</p>
                                 <p>{i.one}</p>
                                 <p>{i.two}</p>
                                 <p>{i.three}</p>
                                 <p>{i.four}</p>
                             </div>
                         );
                    })}
                </div>
                <div className='fSection liabilities'>
                    <h3>Balance Sheet - Liabilities</h3>
                    <div className='tbl head'>
                        <p></p>
                        <p>YE 08/31/17</p>
                        <p>YE 08/31/18</p>
                        <p>YE 08/31/19</p>
                        <p>YE 08/31/20</p>
                    </div>
                    {stats.balance.liabilities.map(i => {
                         return (
                             <div className='tbl row'>
                                <p>{i.rowTitle}</p>
                                 <p>{i.one}</p>
                                 <p>{i.two}</p>
                                 <p>{i.three}</p>
                                 <p>{i.four}</p>
                             </div>
                         );
                    })}
                </div>
                <div className='fSection operations'>
                    <h3>Statement of Operations</h3>
                    <div className='tbl head'>
                        <p></p>
                        <p>YE 08/31/17</p>
                        <p>YE 08/31/18</p>
                        <p>YE 08/31/19</p>
                        <p>YE 08/31/20</p>
                    </div>
                    {stats.operations.map(i => {
                         return (
                             <div className='tbl row'>
                                <p>{i.rowTitle}</p>
                                 <p>{i.one}</p>
                                 <p>{i.two}</p>
                                 <p>{i.three}</p>
                                 <p>{i.four}</p>
                             </div>
                         );
                    })}
                </div>
        	</section>
        	<section className='slides'>
        		<div className='slide'>
        			<img src={First} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={September} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={October} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={November} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={December} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={January} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={February} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={March} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={April} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={AprilC} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={May} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={MayC} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={June} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={July} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={ThankYou} alt="Slide from Annual Meeting PDF"/>
        		</div>
        		<div className='slide'>
        			<img src={Final} alt="Slide from Annual Meeting PDF"/>
        		</div>
        	</section>
        </Container>
    );
}

export default AnnualMeeting;