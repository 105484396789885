import React, { useEffect } from 'react';
import styled from 'styled-components';
import Philosophy from './images/philosophy.jpg';
import Board from './images/resthaveBoardMembers.jpg';
import History from './pdfs/Resthave_Home_History.pdf';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-101155498-7');

const Container = styled.div`

    .history{
        width: 100vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgb(4, 44, 109);
        color: white;

        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            h1{
                font-size: 3rem;
                margin: 10px 0 0 0;
                padding: 35px 0 10px 0;

                @media(max-width: 1100px){
                    font-size: 3.5rem;
                }

                @media(max-width: 600px){
                    font-size: 4rem;
                }
            }

            p{
                font-size: 1.8rem;
                text-align: center;
                margin: 10px 0;

                @media(max-width: 1100px){
                    font-size: 2.4rem;
                }
            }
        }

        .churches{
            display: flex;
            width: 80%;
            justify-content: space-around;
            margin: 30px 0;
            padding: 30px 0;
            border-top: 1px solid white;

            @media(max-width: 1100px){
                flex-direction: column;
                align-items: center;
            }

            ul{

                li{
                    font-size: 1.8rem;

                    @media(max-width: 1100px){
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }

    .mission{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px;

        h2{
            font-size: 3rem;
            margin: 10px 0 0 0;
            text-align: center;

            @media(max-width: 1100px){
                font-size: 3.5rem;
            }
        }

        p{
            font-size: 1.8rem;
            text-align: center;
            margin: 10px 0;

            @media(max-width: 1100px){
                font-size: 2.4rem;
            }
        }
    }

    .philosophy{
        width: 100%;
        display: flex;
        background: rgb(4, 44, 109);
        color: white;
        padding: 30px 0;

        @media(max-width: 1100px){
            flex-direction: column;
        }

        .text{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media(max-width: 1100px){
                width: 100%;
            }

            h2{
                width: 80%;
                font-size: 3rem;
                text-align: center;

                @media(max-width: 1100px){
                font-size: 3.5rem;
            }
            }

            p{
                width: 80%;
                font-size: 1.7rem;
                text-align: center;

                @media(max-width: 1100px){
                font-size: 2.4rem;
                }
            }
        }

        .image{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width: 1100px){
                width: 100%;
            }

            img{
                width: 80%;
                border-radius: 20px;
            }
        }
    }

    .board{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        border-bottom: 2px solid rgb(4, 44, 109);
        margin: 0 0 30px 0;

        h2{
            font-size: 3rem;
        }

        img{
            width: 70%;
            border-radius: 20px;

            @media(max-width: 600px){
                width: 100%;
            }
        }

        p{
            font-size: 1rem
        }

    }
`;

const About = (props) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <Container>
            <section className="history">
                <div className="main">
                    <h1>Our History</h1>
                    <p>You can view our history book <a href={History} target="_blank" rel="noopener noreferrer">here</a></p>
                    <p>On January 15, 1955 a meeting was held in the Emanuel Reformed Church in Morrison, Illinois to begin a study for a proposed “Home for the Aged”. The Reformed Church Classis of Illinois had adapted this committee. Those selected were from Morrison and Fulton Reformed Churches. At the April 22, 1955 meeting, a motion was made to include all Reformed and Christian Reformed Churches in Whiteside County in this new project. After monthly meetings and much study and discussion, a motion was made at the September 13, 1955 meeting to vote on whether or not to proceed in establishing a “Home for the Aged”. This motion was unanimously approved and another motion was made to elect ten men, one from each of the following churches, to take steps to form a corporation for this “Home”.</p>
                </div>
                <div className="churches">
                    <ul>
                        <li>Bethesda Lutheran - Morrison, Illinois</li>
                        <li>Ebenezer Reformed - Morrison, Illinois</li>
                        <li>First Baptist - Morrison, Illinois</li>
                        <li>Newton Zion Reformed - Erie, Illinois</li>
                        <li>St. Mary's Catholic - Morrison, Illinois</li>
                        <li>United Methodist - Morrison, Illinois</li>
                    </ul>
                    <ul>
                        <li>Christian Reformed - Morrison, Illinois</li>
                        <li>Emmanuel Reformed - Morrison, Illinois</li>
                        <li>First Presbyterian - Morrison, Illinois</li>
                        <li>St. Anne's Episcopal - Morrison, Illinois</li>
                        <li>St. Peter's Lutheran - Morrison, Illinois</li>
                        <li>United Methodist - Morrison, Illinois</li>
                    </ul>
                </div>
            </section>
            <section className="mission">
                <h2>Our Mission</h2>
                <p>The mission of Resthave Home is to provide compassionate care while promoting human wholeness in a home-like atmosphere.</p>
            </section>
            <section className="philosophy">
                <div className="text">
                    <h2>Our Philosophy</h2>
                    <p>Resthave Home participates in Christian Witness - a healing ministry of justice, love, peace and service. We affirm our commitment to serve our community, especially the elderly and disabled. In our works, we affirm the dignity, respect and value of each individual. We dedicate our efforts to aid all persons... physically, spiritually, socially and intellectually. We acknowledge health as a basic human value and commit our efforts to promote, preserve, protect and restore wellness wherever we are able. We embrace the opportunity to extend compassionate care, dignity, peace and tranquility, even at the end of life. We pledge ourselves to these values and beliefs; we commit ourselves to continually seek out ways of embodying them in our services, actions and activities.</p>
                </div>
                <div className="image">
                    <img src={Philosophy} alt="Philosophy Alternative"/>
                </div>
            </section>
            <section className="board">
                <h2>The Resthave Board</h2>
                <img src={Board}  alt="Board Alternative"/>
                <p>Members - Michele Workman, Jerry Lindsey, Susan Tegeler, John Hauptman, Chick West, Mary Beswick, Martin Schuette, Duane Habben, Gretchen Bush, Marcia Haag, Louisa Thomas</p>
            </section>
        </Container>
    );
}

export default About;