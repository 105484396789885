import React from 'react';
import styled from 'styled-components';
import SingleCard from './card';
import Vets from './images/veteransResthave.jpg';
import Therapy from './images/skilledThreeRepl.jpg';
import Chapel from './images/resthaveChapel.jpg';
import Mail from './images/mail.jpg';
import Sensory from './images/sensoryPerceptionRoom.jpg';
import Hospice from './images/resthaveHospiceSuit.jpg';
import Housekeeping from './images/housekeeping.jpg';
import Barbershop from './images/barbershop.jpg';
import Maintenance from './images/maintenance.png';
import Pharmacy from './images/pharmacy.png';
import Podiatry from './images/podiatry.jpg';
import Activities from './images/activityOneResthave.jpg';
import Courtyard from './images/resthaveCourtyard.jpg';
import SocialServices from './images/socialservices.jpg';
import Library from './images/resthaveLibrary.jpg';
import Supplies from './images/toilet.jpg';
import Telephone from './images/internet.png';
import Internet from './images/tele.jpg';
import Transportation from './images/resthaveVan.jpg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    .header{
        text-align: center;

        h1{
            @media(max-width: 1100px){
                font-size: 4rem;
                text-align: center;
            }
        }

        p{
            @media(max-width: 1100px){
                font-size: 2.4rem;
                text-align: center;
                width: 90%;
            }
        }
    }

    .cards{
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

    }
`;

const Amenities = (props) => {

    return (
        <Container>
            <div className="header">
                <h1>Amenities at Resthave</h1>
                <p>These are the amenities offered at resthave:</p>
            </div>
            <div className="cards">

                <SingleCard image={Vets} header='Veterans at Resthave' text='We are proud to be a VA contracted home! This means the Veterans Administration will pay for those veterans who meet criteria to stay at Resthave. We have a Veterans Honor Wall and have several special events during the year to acknowledge the service and sacrifices our veterans have made for this great country.' />

                <SingleCard image={Therapy} header='Therapy' text='Our excellent therapy team gives patient-centered care that keeps you at your best whether you are here for long or short-term care. Our services are physician directed and include physical, occupational and speech therapies.' />

                <SingleCard image={Chapel} header='Chapel' text='Our elegant and generously sized chapel has been a venue for baptisms, weddings and end of life celebrations and services. It features beautiful stained glass and a baby grand piano. Weekly evening services are held with our local churches officiating. Weekly bible study is also provided in our chapel for those that wish to attend.' />

                <SingleCard image={Mail} header='Mail and Newspapers' text="Nursing mail is delivered to the resident's room on normal delivery days. The Activities Department assists residents as needed to read or send mail. Assisted Living mail is delivered to Residents' assigned mailboxes. Outgoing mail may be deposited in a centralized mailbox provided. Stamps may be purchased in the office. Newspapers are available on a subscription basis." />

                <SingleCard image={Sensory} header='Sensory Perception' text='Our sensory perception room is a nice quiet getaway nook where our residents can enjoy a more relaxing atmosphere. This room features, an old fashioned styled radio disc player for soothing music and sounds, two bubble sensory tubes and a fireplace. It features items that our more impaired residents can touch and feel different textures such as fidget quilts to keep hands busy yet can have a calming effect.' />

                <SingleCard image={Hospice} header='Hospice Suite' text='Our hospice suite provides additional space with a home-like atmosphere and calming environment that will benefit our residents and families alike. The suite features a couch that pulls out into a bed for family members should they prefer to stay overnight with their loved one. It also offers a fireplace, refrigerator, DVD player and other items to keep families busy and comfortable when spending time with their loved one.' />

                <SingleCard image={Housekeeping} header='Housekeeping Services' text='Our housekeeping and laundry service is outstanding as you will recognize upon a visit to Resthave. After permanently marking clothes upon admittance, laundry is done on a daily basis.' />

                <SingleCard image={Barbershop} header='Beauty Shop and Barber' text='A barber and beautician are available on site. Manicures are also available through the activities department.' />

                <SingleCard image={Maintenance} header='Maintenance' text='Our maintenance team keeps both the inside and outside of our home in safe working condition. They quickly solve any issues with room furnishings and are available to hang pictures.' />

                <SingleCard image={Pharmacy} header='Pharmacy Services' text='All medication including “over the counter” drugs, ointments, etcetera, must be ordered by the physician and are billed directly from the pharmacy. For our long term care residents all medications are administered by a licensed nurse.' />

                <SingleCard image={Podiatry} header='Podiatry' text='A podiatrist provides regular on-site visits to help with toenail and skin conditions, arthritic foot care, diabetic foot care, foot and ankle pain and more. Residents have a choice from 3 podiatrists that come to Resthave.' />

                <SingleCard image={Activities} header='Activities' text='We have many amenities and activities available to meet your needs such as a courtyard, raised gardens, chapel, library, lounges, exercise room and more. With both a variety of activities and the ability to find peace and quiet in your apartment – at Resthave the choice is yours. The staff is caring, friendly and compassionate and go the extra mile to help you in any way they can to meet your needs.' />

                <SingleCard image={Courtyard} header='Courtyard' text='Our beautiful secured courtyard allows residents the freedom to go outdoors when they choose, weather permitting. The courtyard features beautiful flowers of every color, a grilling area and a glider that is wheelchair accessible.' />

                <SingleCard image={SocialServices} header='Social Services' text='Our Social Services Department assists residents and their families in the adjustments necessary for entering our home. Social Services provides an open door to both family and residents to assist with this transition.' />

                <SingleCard image={Library} header='Library' text='Our cozy library has many diversified books to choose from. Our local library lends us books and swaps them out regularly for our residents. If you have something in mind you would like to read and can’t find it, we can help. Our library features greeting cards at no charge that residents can choose from as well as a computer, television, DVD’s and a Nintendo Wii.' />

                <SingleCard image={Supplies} header='Supplies' text="Families are welcome to supply the resident's favorite toiletries and incontinent products. These items are also available through Resthave and therefore would be included in the monthly statement." />

                <SingleCard image={Telephone} header='Telephone Service' text="Residents may have a private telephone line at their own expense. Social Services will assist in establishing / transferring your telephone account. Residents who choose not to have a telephone in their room may use one of the facility' s telephones in a private area." />

                <SingleCard image={Internet} header='Internet Services' text='Residents may bring in a television and a wall mount if desired. Extended Cable is available at no additional charge. A wireless internet connection is also available to residents and guests at no additional charge.' />

                <SingleCard image={Transportation} header='Transportation Services' text='Our facility van is available to transport our residents to and from appointments. It is also used by our Activities Department and Quality of Life Coordinator for resident outings.' />

            </div>
        </Container>
    );
}

export default Amenities;