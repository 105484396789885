import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    width: 130px;
    height: 50px;
    background: #FBFBFC;
    border-radius: 15px;
    margin: 0 20px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
    @media(max-width: 800px) {
        font-size: 12px;
        text-align: center;
    }
`;

const Button = (props) => {
    return (
        <Container target="_blank" href={props.linkto}>{props.text}</Container>
    );
}

export default Button;