import React from 'react';
import styled from 'styled-components';
import Image from './images/admissions.jpg';
import AddEnglish from './pdfs/Resthave_Home_Admission_English.pdf';
import AddSpanish from './pdfs/Resthave_Home_Admission_Espagnol.pdf';

const Container = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;    

    .hero{
        width: 100vw;
        height: 80vh;
        background-image: url(${Image});
        background-position: bottom right;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media(max-width: 1100px){
            height: 30vh;
        }

        h1{
            font-size: 3rem;
        }

    }

    .text{
        width: 60%;
        padding-top: 30px;

        @media(max-width: 1100px){
            width: 90%;
        }

        p{
            font-size: 1.5rem;
            padding: 20px 0;
            text-align: center;

            @media(max-width: 1100px){
                font-size: 1.8rem;
            }
        }

    }

    .policy{
        width: 60%;
        padding: 50px 0;
        color: darkblue;

        @media(max-width: 1100px){
            width: 90%;
        }

        h2{
            font-size: 2rem;
            text-align: center;
            padding: 10px;

            @media(max-width: 1100px){
                font-size: 2.3rem;
            }
        }

        p{
            font-size: 1.5rem;
            text-align: center;

            @media(max-width: 1100px){
                font-size: 1.8rem;
            }
        }

    }

    .forms{
        width: 60%;
        padding: 30px 0;

        @media(max-width: 1100px){
            width: 90%;
        }

        h2{
            font-size: 2rem;
            text-align: center;

            @media(max-width: 1100px){
                font-size: 1.8rem;
            }
        }

        .links{
            width: 100%;
            display: flex;
            justify-content: space-around;

            @media(max-width: 800px){
                flex-direction: column;
                align-items: center;
            }

            a{
                font-size: 1.5rem;
                text-decoration: none;

                @media(max-width: 1100px){
                font-size: 1.8rem;
            }
            }
        }
    }
`;

const Admissions = (props) => {
    return (
        <Container>
            <section className="hero">
                <h1>Admissions at Resthave</h1>
            </section>
            <section className="text">
                <p>Resthave provides twenty-four hour nursing care in our nursing wing and supervised care in our assisted living wing. Persons joining us must be able to have their physical and emotional needs met by the services available at this home.</p>

                <p>Resthave has sixty-four private nursing rooms and three semi-private rooms, each with bathroom and individual climate control. Each room is furnished with a bed, dresser, night stand, window treatments, bedspread, linens, towels and a large closet. Five of those rooms are available for short term rehabilitation patients with a goal of returning home. Each of these rooms have a bathroom with walk-in shower and built in seat.</p>

                <p>We also have thirty seven assisted living apartments. Each apartment has individual climate control, kitchenette with micro-fridge and bathroom with walk-in shower and built in seat. Four of these rooms are extra-large rooms designed for couples.</p>

                <p>We know a nursing home decision may be difficult. We hope this information will help you with this process. Communication and advanced planning are essential.</p>

                <p>There is a link to our pre-admission form on this page. An assessment by Resthave’s admission team is done after resident and/or family member indicate interest in becoming part of the Resthave family. This assessment is completed prior to admission to determine that we are able to safely meet all of your needs.</p>
            </section>
            <section className="policy">
                <h2>Open Admission Policy</h2>
                <p>It is the policy of Resthave Home to admit and treat all residents without regard to race, color, sex, age, disability, national origin, religion, political beliefs, payor source or an individual’s ability to pay. The same requirements for admission are applied to all, and residents are assisted within Resthave Home without regard to race, color, sex, age, disability, national origin, religion, political beliefs, payor source or an individual’s ability to pay. There is no distinction in eligibility for, or in the manner of the providing any resident service provided by or through Resthave Home. All facilities of Resthave Home are available without distinction to all residents and visitors, regardless of race, color, sex, age, disability, national origin, religion, political beliefs, payor source or an individual’s ability to pay.</p>
            </section>
            <section className="forms">
                <h2>Please fill out the following forms and send them to Resthave to apply.</h2>
                <div className="links">
                    <a href={AddEnglish} target="_blank" rel="noopener noreferrer">Admission Form in English</a>
                    <a href={AddSpanish} target="_blank" rel="noopener noreferrer">Admission Form in Espanol</a>
                </div>
            </section>
        </Container>
    );
}

export default Admissions;